import React, { useState, useEffect } from 'react';
import Web from "../components/home/Home_web";
import H5 from "../components/home/Home_h5";
import BUY from "../components/home/Buy";
import SUBSCRIBE from "../components/home/Subscribe";
import BlockCn from "../components/home/BlockCn";
import { isMobile } from "../service/util";

function Home() {
  const [countryCode, setCountryCode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCode = async () => {
      try {
        const response = await fetch('/api/assistant/ip-info', {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        });
        const responseJson = await response.json();
        setCountryCode(responseJson.data.countryCode);
      } catch (error) {
        console.error('Error fetching country:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCode();
  }, []);

  if (loading) {
    return DoGetHome();
  }

  if (countryCode === 'CN') {
    return <BlockCn />;
  }

  return DoGetHome();
}

function DoGetHome() {
  return isMobile() ? <H5 /> : <Web />;
}

function Buy() {
  return <BUY />;
}

function Subscribe() {
  return <SUBSCRIBE />;
}

export default Home;
